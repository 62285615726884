import { log } from "console";
import { LucideProps, type IconNode as LucideIcon } from "lucide-react";

export type Icon = LucideIcon;

export const Icons = {
  logo: (props: LucideProps) => (
    <svg width="151" height="46" viewBox="0 0 151 46" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M16.3984 0.5625H22.9277L6.52932 39.9912H0L16.3984 0.5625Z" fill="currentColor" />
      <path d="M30.7328 0.5625H37.2622L20.9013 39.9912H14.3345L30.7328 0.5625Z" fill="currentColor" />
      <path
        d="M44.2417 9.64145C44.2417 3.67651 48.0317 0 53.3227 0C58.0508 0 61.4656 2.77614 61.991 7.35301H56.3247C56.062 5.47724 54.9738 4.57688 53.3602 4.57688C51.2213 4.57688 49.9455 6.22755 49.9455 9.60394C49.9455 13.1679 51.2588 14.7435 53.3602 14.7435C55.1239 14.7435 56.0996 13.7306 56.3247 11.6298H62.0285C61.6532 16.3942 58.1634 19.3204 53.3227 19.3204C48.0317 19.2829 44.2417 15.5689 44.2417 9.64145Z"
        fill="currentColor"
      />
      <path
        d="M63.5293 12.0806C63.5293 7.80389 66.4938 4.99023 70.9592 4.99023C75.4622 4.99023 78.3891 7.80389 78.3891 12.0806C78.3891 16.3574 75.4247 19.171 70.9592 19.171C66.4562 19.2086 63.5293 16.3949 63.5293 12.0806ZM73.0981 12.0806C73.0981 9.60463 72.3852 8.55419 70.9592 8.55419C69.5333 8.55419 68.8203 9.56711 68.8203 12.0806C68.8203 14.5942 69.5333 15.6071 70.9592 15.6071C72.3852 15.6071 73.0981 14.5942 73.0981 12.0806Z"
        fill="currentColor"
      />
      <path
        d="M102.518 10.0548V18.6833H97.3395V10.9927C97.3395 9.64214 96.6641 9.07941 95.801 9.07941C94.7128 9.07941 93.9998 9.94226 93.9998 11.1052V18.6833H88.8214V10.9927C88.8214 9.64214 88.1459 9.07941 87.2828 9.07941C86.1946 9.07941 85.4817 9.94226 85.4817 11.1052V18.6833H80.3032V5.47793H85.0314L85.219 7.12861C86.1571 5.70303 87.4329 4.99023 89.1216 4.99023C90.8102 4.99023 92.2361 5.70303 93.1367 7.12861C94.2625 5.628 95.6884 4.99023 97.4521 4.99023C100.417 4.99023 102.518 6.75346 102.518 10.0548Z"
        fill="currentColor"
      />
      <path
        d="M119.704 12.079C119.704 16.5058 117.453 19.2069 114 19.2069C112.312 19.2069 110.961 18.5316 110.023 17.2936L109.873 18.7192H105.069V0.449219H110.248V6.63926C111.336 5.55131 112.649 4.98858 114.113 4.98858C117.265 4.98858 119.704 7.42708 119.704 12.079ZM114.413 12.004C114.413 9.86558 113.587 8.96521 112.424 8.96521C111.036 8.96521 110.21 10.2032 110.21 12.004C110.21 13.8047 111.073 15.0052 112.424 15.0052C113.625 15.0052 114.413 14.1048 114.413 12.004Z"
        fill="currentColor"
      />
      <path
        d="M121.618 0.449219H126.797V3.78809H121.618V0.449219ZM121.618 5.47628H126.797V18.6817H121.618V5.47628Z"
        fill="currentColor"
      />
      <path
        d="M35.9863 33.9513H41.7652C41.8777 35.3769 42.7783 36.0146 44.542 36.0146C46.043 36.0146 46.7935 35.5644 46.7935 34.6266C46.7935 33.8387 46.2306 33.4261 45.1799 33.201L41.3524 32.3381C38.4254 31.7004 36.4366 30.1997 36.4366 27.0484C36.4366 23.7846 38.5755 21.3086 44.1292 21.3086C48.9324 21.3086 51.5216 23.1844 51.7092 26.9734H46.1556C46.0055 26.0355 45.4051 25.5478 44.0542 25.5478C42.8909 25.5478 42.1404 25.8855 42.1404 26.6358C42.1404 27.161 42.5157 27.5361 43.5288 27.7612L47.3564 28.6241C50.9587 29.4119 52.5723 31.4753 52.5723 34.3264C52.5723 38.3406 49.3827 40.5915 44.2793 40.5915C39.251 40.5915 36.3241 38.3031 35.9863 33.9513Z"
        fill="currentColor"
      />
      <path
        d="M68.8581 26.748L63.6046 41.8292C62.7415 44.2302 61.3156 45.2056 58.5763 45.2056H55.5367V41.1164H57.3379C58.1635 41.1164 58.5763 40.8538 58.8014 40.2161L58.914 39.8784L53.5854 26.748H59.1016L60.6776 32.0377L61.203 34.7388L63.417 26.748H68.8581Z"
        fill="currentColor"
      />
      <path
        d="M69.9087 35.8277H74.7869C74.937 36.8782 75.65 37.4034 76.9258 37.4034C78.1642 37.4034 78.8396 36.8782 78.8396 36.0528C78.8396 35.3775 78.3893 34.9649 77.3761 34.8898L75.3873 34.7023C71.86 34.4022 70.2089 33.0516 70.2089 30.7256C70.2089 27.837 72.648 26.2988 76.7382 26.2988C80.6408 26.2988 82.8548 27.7619 83.2675 30.463H78.5394C78.3893 29.6002 77.7889 29.15 76.5506 29.15C75.4999 29.15 74.862 29.5252 74.862 30.2379C74.862 30.8382 75.3123 31.2884 76.363 31.4009L78.3518 31.5885C82.0667 31.9636 83.6803 33.5018 83.6803 35.7527C83.6803 38.9415 80.941 40.4796 76.7757 40.4796C72.4979 40.4796 70.2464 38.6789 69.9087 35.8277Z"
        fill="currentColor"
      />
      <path
        d="M92.1237 34.6639C92.1237 35.6017 92.574 36.0894 93.3996 36.0894H94.5628V39.9535H91.5608C88.071 39.9535 86.9453 38.8281 86.9453 35.3391V29.7869L84.9189 29.8244V26.7481L86.9453 26.7856V22.7715H92.1237V26.8231L94.5628 26.7856V29.8619L92.1237 29.8244V34.6639Z"
        fill="currentColor"
      />
      <path
        d="M110.924 34.6648H101.242C101.505 36.2404 102.33 36.9157 103.681 36.9157C104.657 36.9157 105.332 36.5405 105.745 35.7902L110.586 36.0903C109.648 38.829 107.209 40.4796 103.606 40.4796C98.9906 40.4796 96.1763 37.666 96.1763 33.3892C96.1763 29.1125 99.1783 26.2988 103.569 26.2988C108.672 26.2988 110.886 30.1254 110.886 33.9145L110.924 34.6648ZM101.317 31.8511H105.708C105.52 30.463 104.769 29.8628 103.569 29.8628C102.368 29.9003 101.617 30.463 101.317 31.8511Z"
        fill="currentColor"
      />
      <path
        d="M135.052 31.3619V39.9904H129.874V32.2997C129.874 30.9492 129.198 30.3865 128.335 30.3865C127.247 30.3865 126.534 31.2493 126.534 32.4123V39.9904H121.356V32.2997C121.356 30.9492 120.68 30.3865 119.817 30.3865C118.729 30.3865 118.016 31.2493 118.016 32.4123V39.9904H112.837V26.7475H117.566L117.753 28.3981C118.691 26.9726 119.967 26.2598 121.656 26.2598C123.344 26.2598 124.77 26.9726 125.671 28.3981C126.797 26.8975 128.223 26.2598 129.986 26.2598C132.951 26.2973 135.052 28.023 135.052 31.3619Z"
        fill="currentColor"
      />
      <path
        d="M137.229 35.8277H142.107C142.257 36.8782 142.97 37.4034 144.246 37.4034C145.484 37.4034 146.159 36.8782 146.159 36.0528C146.159 35.3775 145.709 34.9649 144.696 34.8898L142.707 34.7023C139.18 34.4022 137.529 33.0516 137.529 30.7256C137.529 27.837 139.968 26.2988 144.058 26.2988C147.961 26.2988 150.175 27.7619 150.587 30.463H145.859C145.709 29.6002 145.109 29.15 143.87 29.15C142.82 29.15 142.182 29.5252 142.182 30.2379C142.182 30.8382 142.632 31.2884 143.683 31.4009L145.672 31.5885C149.387 31.9636 151 33.5018 151 35.7527C151 38.9415 148.261 40.4796 144.096 40.4796C139.855 40.4796 137.566 38.6789 137.229 35.8277Z"
        fill="currentColor"
      />
    </svg>
  ),
  burger: (props: LucideProps) => (
    <svg width="56" height="47" viewBox="0 0 56 47" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M47.7031 20.141L50.2104 26.1697L7.50768 26.1701L5.0003 20.1414L47.7031 20.141Z" fill="currentColor" />
      <path
        d="M22.7029 8.04952L25.2102 14.0782L2.50748 14.0786L9.56999e-05 8.04988L22.7029 8.04952Z"
        fill="currentColor"
      />
      <path d="M52.7029 32.0495L55.2102 38.0782L32.5075 38.0786L30.0001 32.0499L52.7029 32.0495Z" fill="currentColor" />
    </svg>
  ),
  burgerClose: (props: LucideProps) => (
    <svg width="23" height="40" viewBox="0 0 23 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M22.9277 0H16.3984L11.4639 11.8646L6.52937 0H4.88484e-05L8.18981 19.7369L0 39.4287H6.52932L11.4507 27.5955L16.3609 39.4287H22.9277L14.7285 19.7143L22.9277 0Z"
        fill="currentColor"
      />
    </svg>
  ),
  threads: (props: LucideProps) => (
    <svg width="20" height="20" viewBox="0 0 20 20" {...props}>
      <path d="m14.47,9.29c-.08-.04-.16-.08-.25-.11-.14-2.66-1.6-4.18-4.04-4.2-.01,0-.02,0-.03,0-1.46,0-2.67.62-3.42,1.76l1.34.92c.56-.85,1.43-1.03,2.08-1.03,0,0,.01,0,.02,0,.8,0,1.41.24,1.8.69.29.33.48.79.57,1.37-.71-.12-1.48-.16-2.31-.11-2.32.13-3.81,1.49-3.71,3.37.05.95.53,1.77,1.34,2.31.69.45,1.57.67,2.49.62,1.21-.07,2.16-.53,2.83-1.38.5-.64.82-1.48.96-2.52.58.35,1.01.81,1.24,1.36.4.94.43,2.48-.83,3.74-1.1,1.1-2.43,1.58-4.43,1.59-2.22-.02-3.9-.73-4.99-2.12-1.02-1.3-1.55-3.18-1.57-5.58.02-2.4.55-4.28,1.57-5.58,1.09-1.39,2.77-2.1,4.99-2.12,2.24.02,3.95.73,5.08,2.13.56.68.98,1.54,1.25,2.55l1.57-.42c-.33-1.23-.86-2.3-1.58-3.18-1.45-1.79-3.58-2.7-6.32-2.72h-.01c-2.73.02-4.84.94-6.25,2.73-1.26,1.6-1.9,3.82-1.93,6.61h0s0,.01,0,.01c.02,2.79.67,5.01,1.93,6.61,1.41,1.8,3.51,2.71,6.25,2.73h.01c2.43-.02,4.14-.65,5.55-2.06,1.85-1.84,1.79-4.16,1.18-5.58-.44-1.02-1.27-1.84-2.41-2.39Zm-4.2,3.95c-1.02.06-2.07-.4-2.12-1.38-.04-.72.52-1.53,2.19-1.63.19-.01.38-.02.56-.02.61,0,1.17.06,1.69.17-.19,2.41-1.32,2.8-2.32,2.85Z"></path>
    </svg>
  ),
  mirrorLineSmall: (props: LucideProps) => (
    <svg width="13" height="27" viewBox="0 0 13 27" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M10.9108 0H13L2.0892 27H0L10.9108 0Z" fill="#E6007E" />
    </svg>
  ),
  mirrorLine: (props: LucideProps) => (
    <svg width="126" height="294" viewBox="0 0 126 294" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M123.22 0H125.443L2.66639 294H0.443115L123.22 0Z" fill="currentColor" />
    </svg>
  ),
  mirrorLineRight: (props: LucideProps) => (
    <svg width="205" height="484" viewBox="0 0 205 484" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M3.64618 484H0L201.354 0H205L3.64618 484Z" fill="#E6007E" />
    </svg>
  ),
  mirrorLineMenu: (props: LucideProps) => (
    <svg width="30" height="51" viewBox="0 0 30 51" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M21 0.5H29.2586L8.25859 51H0L21 0.5Z" fill="currentColor" />
    </svg>
  ),
  arrowUp: (props: LucideProps) => (
    <svg width="209" height="251" viewBox="0 0 209 251" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M201.912 0.152773C202.38 -0.140609 202.997 0.000879854 203.29 0.468796L208.071 8.09393C208.365 8.56185 208.223 9.179 207.755 9.47239C207.287 9.76577 206.67 9.62428 206.377 9.15636L202.872 3.56611C173.095 129.226 74.8229 214.356 0.88612 250.896L0 249.104C73.5731 212.743 171.309 128.032 200.918 3.13646L195.349 6.62818C194.881 6.92156 194.264 6.78007 193.971 6.31215C193.677 5.84424 193.819 5.22708 194.287 4.9337L201.912 0.152773Z"
        fill="url(#paint0_linear_392_2373)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_392_2373"
          x1="101.443"
          y1="1.00002"
          x2="101.443"
          y2="250"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" stop-color="white" />
        </linearGradient>
      </defs>
    </svg>
  ),
  phone: (props: LucideProps) => (
    <svg width="40" height="46" viewBox="0 0 40 46" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M9.49664 22H18L14.2564 31H6L9.49664 22Z" fill="currentColor" />
      <path d="M24.4971 22H33.0004L29.5038 31H20.7744L24.4971 22Z" fill="currentColor" />
      <path
        d="M8 31V30.5V28V23V15C8 8.37258 13.3726 3 20 3C26.6274 3 32 8.37258 32 15V31C32 37.6274 26.6274 43 20 43"
        stroke="currentColor"
        strokeWidth="5.8"
        strokeLinejoin="round"
      />
      <path d="M17.0003 40.0977H20.0003V45.9062H14.5664L17.0003 40.0977Z" fill="currentColor" />
    </svg>
  ),
  button: (props: LucideProps) => (
    <svg width="260" height="40" viewBox="0 0 260 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M16.3141 0H259.5L243 39.5H0L16.3141 0Z" fill="currentColor" />
    </svg>
  ),
};
